import React from "react"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import Grid from "@app-components/ui/grid/"
import HeaderImage from "@page-components/products/files/features/teams/header/"
import AccessImage from "@page-components/products/files/features/teams/access/"
import PermissionsImage from "@page-components/products/files/features/teams/permissions/"
import AuditImage from "@page-components/products/files/features/teams/audit/"
import HEADERS from "@data/headers/"

const Teams = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="features/teams">
    <Header
      title="Work as a Team"
      text="Easily set up roles and permissions within your account to dictate what everyone can and cannot do."
      image={<HeaderImage />}
      secondaryNavigation={HEADERS.files}
    />
    <Feature
      title="Project Based Access"
      text="Quickly identify who has access to a specific project, and easily add or remove team members right from a project page. On-boarding new users is quick too - you can pick which projects a new user should have access to right when you invite them."
      icon="permissions-white"
      image={<AccessImage />}
    />
    <Feature
      title="Global Roles &amp; Permissions"
      text="Create roles for your team members to define what permissions everyone has across the entire platform. You can choose whether users can download content, send content externally, make purchases, and much more."
      reverse
      icon="roles-white"
      image={<PermissionsImage />}
    />
    <Feature
      title="Detailed Audit Trail"
      text="Keep your team members accountable with robust event logging. Every operation made within CineSend is logged into a detailed, uneditable audit trail that stays with the project for life."
      icon="audit-white"
      image={<AuditImage />}
    />
    <Grid
      title="Additional CineSend Team Benefits"
      columnWidth={4}
      items={[
        {
          title: "MPAA Compliant Mode",
          text: "Enforce use of strong passwords, MFA, session timeouts, and access to storage buckets.",
          _id: 1,
          icon: "mpaa-white"
        },
        {
          title: "Two-Factor Authentication",
          text: `Enable and enforce the use of Two-Factor authentication across team members.`,
          _id: 2,
          icon: "two-factor-white"
        },
        {
          title: "Support for SAML 2.0",
          text: "Use your existing identity provider to allow users to access CineSend with Single Sign-On.",
          _id: 3,
          icon: "users-white"
        }
      ]}
    />
  </Layout>
)

export default Teams
