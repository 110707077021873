import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/teams_access.png/" }) {
          childImageSharp {
            fluid(maxWidth: 900, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Img alt="CineSend Team Access" fluid={data.file.childImageSharp.fluid} />}
  />
)

export default HeaderImage
